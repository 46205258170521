<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Allowance Request</h1>
        </div>
        <v-form ref="ppeRequest" v-model="valid">
          <v-row no-gutters>
            <v-col>
              <SelectField
                :items="allowanceType"
                v-model="formData.allowance"
                :label="'Allowance Type'"
                :itemText="'description'"
                :itemValue="'id'"
                :returnObject="true"
                @onChange="checkTimeSheetItem"
              />
            </v-col>
            <v-col :class="personnel.length > 0 ? 'mb-1' : 'mb-6'">
              <!-- <TextField v-model="formData.personnel" :label="'Personnel'" /> -->
              <v-btn
                class="btn-style justify-start text-style"
                @click.prevent="perSearch = true"
                width="100%"
                height="38"
                outlined
              >
                <v-icon left>mdi-magnify</v-icon> Personnel</v-btn
              >
            </v-col>
            <!-- selected personnel list  -->
            <v-col v-if="selectedPersonnel.length > 0" class="mb-6">
              <v-data-table
                :headers="selectedPersonnelHeader"
                :items="selectedPersonnel"
                :hide-default-footer="true"
              >
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="removePersonnel(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
             <v-col>
              <SelectField
                :items="salaryYear"
                v-model="formData.salaryYear"
                :label="'Year'"
                :itemText="'name'"
                :itemValue="'value'"
                :returnObject="true"
                @onChange="onChangeYear(formData.salaryYear.value)"
              />
            </v-col>

            <v-col>
              <SelectField
                :items="salaryPeriod"
                v-model="formData.salaryPeriod"
                :label="'Salary Cycle'"
                :itemText="'description'"
                :itemValue="'id'"
                :returnObject="true"
                @onChange="onChangeSalaryCycle(formData.salaryPeriod.id)"

              />
            </v-col>

            <v-col cols="6" class="pr-1" v-if="formData.allowance.isVacationAllowance === false">
              <v-text-field
                v-model="formData.startDate"
                :type="'date'"
                outlined
                dense
                :label="'Start Date'"
                :disable="!formData.salaryPeriod.startDate"
                :rules="[v => (v >= formatDate(startDate) &&  v <= formatDate(endDate) ) || 'Not Within Salary Cycle']"
                :min="startDate"
                :validate="true"
                :max="endDate"
              />
            </v-col>
            <v-col cols="6" class="pl-1" v-if="formData.allowance.isVacationAllowance === false">
              <v-text-field
                v-model="formData.resumptionDate"
                :type="'date'"
                outlined
                dense
                :label="'End Date'"
                :disable="!formData.salaryPeriod.startDate"
                :rules="[v => (v > formatDate(formData.startDate) &&  v <= formatDate(endDate) ) || 'Not Within Selected Date Range']"
                :min="startDate"
                :validate="true"
                :max="endDate"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-col cols="12" class="d-flex justify-end" v-if="startDate && endDate && formData.allowance.isVacationAllowance === false">
          <Button
            :label="'Generate Request'"
            :btnType="'Submit'"
            @onClick="generateRequest"
            :disabled="!valid"
            :isLoading="creating"
            class=""
          />
        </v-col>
        <v-col  v-if="formData.allowance.isVacationAllowance === true">
              <SelectField
                :items="VacationYears"
                v-model="VacationYear"
                :label="'Vacation Year'"
              />
            </v-col>
      </div>
    </div>
    <!-- request calendar  -->

    <div v-if="personnel.length > 0 && valid" class="scroll row ">
      <div class="col-md-10 col-sm-12 ml-auto mr-auto">
        <div v-if="overTime">
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Personnel</th>
                    <th v-for="item in headers" :key="item.date">
                      <p class="cal-date">{{ item.date }}</p>
                      <p class="cal-day">{{ item.day}}</p>
                    </th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pr in personnel" :key="pr.name">
                    <td>{{ pr.name }}</td>
                    <td v-for="item in headers" :key="item.date">
                      <input
                        type="number"
                        :disabled="item.disable()"
                        placeholder="Work Hours"
                        max="24"
                        value="comment.value"
                        :input="comments"
                        class="input"
                        @keypress="workHourInput($event)"
                        oninput="(!validity.rangeOverflow||(value=24)) ;"
                        @blur="comments(pr.id, $event.target.value, item.value)"
                      />

                    </td>
                    <td>
                      <input
                        placeholder="Comment"
                        v-model="formData.comments"
                        class="comment"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

        </div>
        <div v-if="overTime === false">
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th>Personnel</th>
                    <th v-for="item in headers" :key="item.date">
                     <p class="cal-date">{{ item.date }}</p>
                      <p class="cal-day">{{ item.day}}</p>
                    </th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pr in personnel" :key="pr.name">
                    <td>
                      <input
                        type="checkbox"
                        class="checkbox"
                        :value="pr.id"
                        v-model="selectAllItems"
                        @change="selectAll(pr.id, headers)"
                      />
                    </td>
                    <td>{{ pr.name }}</td>
                    <td v-for="item in headers" :key="item.date">
                      <input
                        type="checkbox"
                        class="checkbox"
                        :disabled="item.disable()"
                        :value="{ date: item.value, employeeId: pr.id }"
                        v-model="selected"
                      />

                    </td>
                     <td>
                       <input
                        placeholder="Comment"
                        v-model="formData.comments"
                        class="comment"
                      />
                     </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </div>
        </div>
      </div>
    </div>
    <!--end request calendar  -->
    <v-row>
      <v-col cols="9 mt-5" class="d-flex justify-end">
        <Button
          :label="'Submit'"
          :btnType="'Submit'"
          :color="'primary'"
          @onClick="dialog = true"
          :disabled="!valid"
          :isLoading="isSaving"
          class="mr-4"
        />

        <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
      </v-col>
    </v-row>
     <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSaving"  :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
     <PersonnelSearch :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false"/>
  </v-app>
</template>
<script>
// import TextField from '@/components/ui/form/TextField.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import {
  employeeService,
  requestService,
  salaryPeriodService,
  salaryComponentService
} from '@/services'
import { CREATE_REQUEST } from '@/store/action-type'
import moment from 'moment'

export default {
  components: {
    // TextField,
    Button,
    SelectField,
    ConfirmationDialog,
    PersonnelSearch
  },
  data () {
    return {
      creating: false,
      alertMessage: '',
      alert: false,
      dialog: false,
      alertType: '',
      perSearch: false,
      ItemType: null,
      VacationYears: [],
      search: '',
      valid: false,
      selectAllItems: [],
      isSaving: false,
      training: '',
      VacationYear: '',
      loadingPersonnelData: false,
      formData: {
        allowance: {},
        salaryPeriod: {},
        // salaryYear: {},
        trainingTypeId: '',
        training: '',
        RequestTypeId: 6,
        startDate: '',
        resumptionDate: '',
        comments: '',
        isClaim: false
      },
      overTime: false,
      request: [],

      trainingType: [],

      personnelSearch: [],

      allowanceType: [],
      salaryPeriod: [],
      salaryYear: [],
      headers: [],
      salaryPeriodYears: [],
      selected: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      selectedPersonnelHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        { text: 'Actions', value: 'actions' }
      ],
      requestHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Training',
          value: 'training'
        },
        {
          text: 'Cost',
          value: 'amount'
        },
        {
          text: 'StartDate',
          value: 'startDate'
        },
        {
          text: 'EndDate',
          value: 'endDate'
        },
        {
          text: 'shortDescription',
          value: 'shortDescription'
        },
        { text: 'Comment', value: 'comments' },
        { text: 'Actions', value: 'actions' }
      ],
      personnel: [],
      selectedPersonnel: []
    }
  },
  computed: {
    endDate: function () {
      if (!this.formData.salaryPeriod.endDate) {
        return
      }
      return this.formData.salaryPeriod.endDate.split('T')[0]
    },
    startDate: function () {
      if (!this.formData.salaryPeriod.startDate) {
        return
      }
      return this.formData.salaryPeriod.startDate.split('T')[0]
    }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    formatDate (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    // getSalaryYear () {
    // const currentYear = new Date().getFullYear()
    // const previousYear = currentYear - 1
    // const nextYear = currentYear + 1
    // this.salaryYear = [
    //   { 'name': `Year ${previousYear}`, 'value': previousYear },
    //   { 'name': `Year ${currentYear}`, 'value': currentYear },
    //   { 'name': `Year ${nextYear}`, 'value': nextYear }
    // ]
    // },
    onChangeYear (val) {
      console.log('year: ', val)
      salaryPeriodService
        .getAllSalaryPeriodByYear(val)
        .then(result => {
          this.salaryPeriod = result.data
        })
        .catch(() => {})
      // this.getAYearBackward()
    },
    onChangeSalaryCycle (id) {
      const val = this.salaryPeriod.filter(item => item.id === id)
      this.formData.startDate = this.formatDate(val[0].startDate)
      this.formData.resumptionDate = this.formatDate(val[0].endDate)
    },

    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    personnelSearchFunction () {
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      this.loadingPersonnelData = true

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
      }).catch(() => {
        this.showAlertMessage('Failed to load Personnnel', 'error')
      }).finally(() => {
        this.loadingPersonnelData = false
      })
    },
    comments (id, value, date) {
      // console.log(value)
      if (value !== '') {
        const newArray = this.selected.filter(comm => comm.date !== date)
        this.selected = newArray
        this.selected.push({
          employeeid: id,
          workHours: parseInt(value),
          date
        })
      } else {
        const newArray = this.selected.filter(comm => comm.date !== date)
        this.selected = newArray
      }
    },
    selectAll (id, data) {
      console.log(data)
      let val = this.selectAllItems.filter(item => item === id)
      if (val.length > 0) {
        data.forEach((d) => {
          if (d.disable()) {
            return
          }
          this.selected.push({ 'date': d.value, 'employeeId': id })
        })
      } else {
        let newSelect = this.selected.filter((sel) => sel.employeeId !== id)
        this.selected = newSelect
      }
    },
    workHourInput (e) {
      if (e.keyCode === 45) {
        // e.preventDefault()
      }
    },
    selectPersonnel (item) {
      let index = null
      for (let i = 0; i < this.selectedPersonnel.length; i++) {
        if (this.selectedPersonnel[i].id === item.id) {
          index = i
          break
        }
      }
      if (index === 0) {
        return
      }

      this.selectedPersonnel.push({
        name: `${item.name}`,
        id: item.id,
        comment: ''
      })
      this.search = ''
    },
    removePersonnel (item) {
      let index = null
      for (let i = 0; i < this.selectedPersonnel.length; i++) {
        if (this.selectedPersonnel[i].id === item.id) {
          index = i
          break
        }
      }
      this.selectedPersonnel.splice(index, 1)
    },

    checkTimeSheetItem (item) {
      this.onReset()
      let id = this.formData.allowance.id
      salaryComponentService
        .getSalaryComponent(id)
        .then(result => {
          if (result.data.timesheetItemType === 1) {
            this.overTime = true
          } else {
            this.overTime = false
          }
        })
        .catch(() => {})
    },

    removeRequest (item) {
      let index = null
      for (let i = 0; i < this.request.length; i++) {
        if (this.request[i].id === item.id) {
          index = i
          break
        }
      }

      this.request.splice(index, 1)
    },
    generateRequest () {
      this.creating = true
      const allowanceRequester = []

      this.selectedPersonnel.forEach(per => {
        allowanceRequester.push({
          EmployeId: per.id,
          RequestTypeId: 1,
          RequestStartDate: this.startDate,
          salaryComponentId: this.formData.allowance.id,
          SalaryPeriodId: this.formData.salaryPeriod.id
        })
      })

      requestService
        .validateRequests(allowanceRequester)
        .then(result => {
          this.personnel = this.selectedPersonnel
          let existingDates = []
          if (result.data[0].existingDates.length !== 0) {
            existingDates = (result.data[0].existingDates)
          }
          if (this.formData.startDate === '') {
            this.headers = this.dateArray(
              this.startDate,
              this.endDate,
              existingDates
            )
          } else if (this.formData.startDate !== '' && this.formData.resumptionDate === '') {
            this.headers = this.dateArray(
              this.formData.startDate,
              this.endDate,
              existingDates
            )
          } else {
            this.headers = this.dateArray(
              this.formData.startDate,
              this.formData.resumptionDate,
              existingDates
            )
          }
        })
        .catch(() => {})
      this.creating = false
    },
    dateArray (startDate, endDate, existingDates) {
      let dateArr = []
      let prevDate = moment(startDate)._d

      let nextDate = moment(endDate)._d

      prevDate = moment(prevDate).format('MM-DD-YYYY')
      nextDate = moment(nextDate).format('MM-DD-YYYY')

      var start = new Date(prevDate)
      var end = new Date(nextDate)

      while (start <= end) {
        const val = existingDates.find(date => {
          return moment(date).format('YYYY-MM-DD') === moment(start).format('YYYY-MM-DD')
        })
        dateArr.push({
          date: moment(start).format('MMM Do'),
          day: moment(start).format('dddd'),
          value: moment(start).format('YYYY-MM-DD'),
          disable: () => {
            if (val) {
              return true
            }
            return false
          }
        })
        var newDate = start.setDate(start.getDate() + 1)
        start = new Date(newDate)
      }

      return dateArr
    },
    onSave () {
      const startDate = this.formatDate(this.formData.salaryPeriod.startDate)
      const endDate = this.formatDate(this.formData.salaryPeriod.endDate)
      if (this.formData.startDate >= startDate && this.formData.resumptionDate > this.formData.startDate && this.formData.resumptionDate <= endDate) {
        let data = []
        if (this.formData.allowance.isVacationAllowance === true) {
          this.selectedPersonnel.forEach(per => {
            return data.push({
              Employeeid: per.id,
              salaryPeriodId: this.formData.salaryPeriod.id,
              requestTypeId: 1,
              // startDate: per.date,
              LeaveYear: this.formData.salaryYear.value,
              LeaveDays: 1,
              isClaim: this.formData.isClaim,
              salaryComponentId: this.formData.allowance.id,
              // resumptionDate: ResumptionDate,
              VacationYear: this.VacationYear
            })
          })
        } else {
          if (this.overTime) {
            this.selected.forEach(per => {
              const ResumptionDate = moment(per.date)
                .add(1, 'days')
                .format('YYYY-MM-DD')
              return data.push({
                Employeeid: per.employeeid,
                salaryPeriodId: this.formData.salaryPeriod.id,
                LeaveYear: this.formData.salaryYear.value,
                requestTypeId: 1,
                startDate: per.date,
                LeaveDays: 1,
                workHour: per.workHours,
                isClaim: this.formData.isClaim,
                salaryComponentId: this.formData.allowance.id,
                resumptionDate: ResumptionDate,
                Comments: this.formData.comments,
                VacationYear: this.VacationYears
              })
            })
          }
          if (this.overTime === false) {
            this.selected.forEach(per => {
              const ResumptionDate = moment(per.date)
                .add(1, 'days')
                .format('YYYY-MM-DD')
              return data.push({
                Employeeid: per.employeeId,
                salaryPeriodId: this.formData.salaryPeriod.id,
                LeaveYear: this.formData.salaryYear.value,
                requestTypeId: 1,
                startDate: per.date,
                LeaveDays: 1,
                isClaim: this.formData.isClaim,
                salaryComponentId: this.formData.allowance.id,
                resumptionDate: ResumptionDate,
                comment: this.formData.comments,
                VacationYear: this.VacationYears
              })
            })
          }
        }

        this.isSaving = true

        console.log('Data', data)
        this.$store
          .dispatch(CREATE_REQUEST, { type: 'allowance', data })
          .then(() => {
            this.showAlertMessage('Request creation successful', 'success')
            this.isSaving = false
            this.$router.go()
          })
          .catch(() => {
            this.showAlertMessage('Request creation failed', 'error')
            this.$router.go()
          })
          .finally(() => {
            this.isSaving = false
            this.dialog = false
            this.onReset()
          })
      } else {
        this.showAlertMessage('Invalid date selection', 'error')
        this.dialog = false
      }
    },
    onReset () {
      this.personnel = []
      this.formData.salaryPeriod = {}
      this.formData.salaryYear = {}
      // this.formData.allowance = {}
      this.formData.training = ''
      this.formData.startDate = ''
      this.formData.endDate = ''
      this.formData.resumptionDate = ''
      this.selectedPersonnel = []

      // this.$refs.ppeRequest.reset()
    },
    getAYearBackward () {
      this.VacationYears = [moment().subtract(1, 'year').format('YYYY'), moment().format('YYYY')]
    }
  },
  mounted () {
    salaryComponentService
      .getRequestSalaryAllowance()
      .then(result => {
        this.allowanceType = result.data
      })
      .catch(() => {})
    requestService
      .getTrainingType()
      .then(result => {
        this.trainingType = result.data
      })
      .catch(() => {})

    salaryPeriodService
      .getSalaryYears()
      .then(result => {
        const mYear = result.data
        mYear.forEach((dat, index) => {
          this.salaryYear.push({ 'name': `Year ${parseInt(mYear[index])}`, 'value': parseInt(mYear[index]) }
          )
        })
      })
      .catch(() => {})

    this.getAYearBackward()
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style {
  text-transform: capitalize;
}
.input {
  border: 1px solid grey;
  height: 30px;
  padding-left: 5px;
  width: 90px;
}
.input:disabled {
  background: #a0a0a0;
}
.comment {
  border: 1px solid grey;
  height: 30px;
  padding-left: 5px;
  width: 190px;
}
.cal-day {
  font-size: 13px;
  margin: 0 !important;
  font-weight: 100;
  width: 75px;
}
.cal-date {
  font-size: 15px;
  margin: 0 !important;
}
</style>
